import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseconfig';
import { getDatabase, ref, update, get } from 'firebase/database'; // Update import to include 'update' and 'get'
import axios from 'axios';

const PaymentCFRef = ({ doPayment }) => {
  const [orders, setOrders] = useState([]);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    if (userId) {
      const database = getDatabase();
      const ordersRef = ref(database, 'orders');
      
      get(ordersRef).then((snapshot) => {
        if (snapshot.exists()) {
          const ordersData = snapshot.val();
          // Convert orders object to array
          const ordersArray = Object.entries(ordersData).map(([key, value]) => ({ ...value, key }));
          // Filter orders for the current user
          const filteredOrders = ordersArray.filter(order => order.customer_id === userId)
          .sort((a, b) => b.order_id - a.order_id);
          setOrders(filteredOrders);
        } else {
          console.log('No orders found for the current user');
        }
      }).catch(error => console.error("Error getting orders:", error));
    }
  }, [userId]);

  const handleRetryPayment = (paymentSessionId) => {
    // Implement logic to retry payment using paymentSessionId
    doPayment(paymentSessionId);
  };

  const trackOrderStatus = async (orderId) => {
    try {
      const response = await axios.get(
        `https://us-central1-trilect-infra-stuck-pro-dev.cloudfunctions.net/fetchOrderStatus?order_id=${orderId}`
      );

      // Find the key corresponding to the given orderId in the orders array
      const orderIndex = orders.findIndex(order => order.order_id === orderId);
      if (orderIndex !== -1) {
        // Update order status in the Firebase Realtime Database
        const orderKey = orders[orderIndex].key;
        const database = getDatabase();
        const orderRef = ref(database, `orders/${orderKey}`);
        await update(orderRef, { payment_status: response.data.data.order_status,  payment_session_id: response.data.data.payment_session_id, 
          created_at:response.data.data.created_at, 
          order_amount:response.data.data.order_amount });
        
        // Update the local state to reflect the updated order status
        const updatedOrders = [...orders];
        updatedOrders[orderIndex] = { ...updatedOrders[orderIndex], payment_status: response.data.data.order_status,
          payment_session_id: response.data.data.payment_session_id, 
          created_at:response.data.data.created_at, 
          order_amount:response.data.data.order_amount };
        setOrders(updatedOrders);
        
        console.log('Order status updated successfully');
      } else {
        console.log('Order not found in local state');
      }
    } catch (error) {
      console.error('Error tracking order status:', error);
    }
  };

  return (
    <div className="container mt-5" style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <h3>Previous Payment History</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Amount</th>
            <th>Payment Status</th>
            <th>Order Status</th>
            <th>Retry Payment</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.key}> {/* Use 'key' instead of 'id' for the key attribute */}
              <td>{order.order_id}</td>
              <td>{order.order_currency} {order.order_amount}</td>
              <td>{order.payment_status}</td>
              <td>
                <button className='btn btn-info' onClick={() => trackOrderStatus(order.order_id)}>Track</button>
              </td>

              <td>
                {order.payment_status == 'ACTIVE' && (
                  <button className='btn btn-primary' onClick={() => handleRetryPayment(order.payment_session_id)}>Retry</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentCFRef;
