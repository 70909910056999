import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../firebaseconfig';
import { getDatabase, ref, push, set, get } from 'firebase/database';
import { AlertContext } from './AlertContext';
import { load } from "@cashfreepayments/cashfree-js"; // Import Cashfree SDK
import { getCountry } from 'country-currency-map';
import PaymentCFRef from './PaymentCFRef'; // Import PaymentCFRef component

const Tmp = () => {
  const [cashfree, setCashfree] = useState(null); // State for Cashfree SDK

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfreeInstance = await load({
          mode: "production", // Switch to "production" mode for production
        });
        setCashfree(cashfreeInstance);
      } catch (error) {
        console.error("Failed to initialize Cashfree SDK", error);
      }
    };

    initializeSDK();
  }, []);

  const getCurrencyFromCountry = (countryName) => {
    // Get the currency based on the country name
    const currency = getCountry(countryName);
    return currency.currency;
  };  
 
 
  const handlePayment = async () => {
    try {
      const database = getDatabase();
      const ordersRef = ref(database, 'orders');
      const userId = auth.currentUser.uid;
      
      // Fetch customer phone number and country name from profile node
      const kycDataRef = ref(database, `users/${userId}/profile/mobileNumber`);
      const countryNameRef = ref(database, `users/${userId}/profile/country`);
      const [kycDataSnapshot, countryNameSnapshot] = await Promise.all([
        get(kycDataRef),
        get(countryNameRef)
      ]);
      
      const customerPhone = kycDataSnapshot.val();
      const countryName = countryNameSnapshot.val();
      const currency = getCurrencyFromCountry(countryName);

        // Push the new order data to the database
        const newOrderData = {
          order_id: 97,
          customer_id: userId,
          customer_phone: customerPhone,
          order_amount: 1070.60,
          order_currency: currency,
          created_at: new Date().toISOString(), // Current timestamp
          payment_session_id: "orderData.data.payment_session_id",
        };
        
        const newOrderRef = push(ordersRef);
        set(newOrderRef, newOrderData);

   
    } catch (error) {
      console.error('Error creating order:', error);
    
    }
  };


  return (
    <div className="container mt-5">
      <div className='row justify-content-center'>
        <div className="col-md-6 border border-secondary rounded shadow py-3 px-5 text-center">
          <h3 className='text-center text-primary'>Payment By CashFree Payment Gateway</h3>
          <hr />
          <p className='text-start text-primary'>Dear customer, to make a payment to us (Trilect Infra Stuck Pro Dev Opc P Ltd) through Debit Card, Credit Card, UPI and other methods will cost Payment Gateway Charges approximately 2% extra. Click Pay Now Button.</p>
          <div className='row'>
            <div className='col-md-12'>
              <br />
              <button type="button" className="btn btn-success w-100" onClick={handlePayment}>
                Pay Now - Amount 1070.60 
              </button>
           
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Tmp;
