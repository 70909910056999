import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../firebaseconfig';
import { getDatabase, ref, push, set, get } from 'firebase/database';
import { AlertContext } from './AlertContext';
import { load } from "@cashfreepayments/cashfree-js"; // Import Cashfree SDK
import { getCountry } from 'country-currency-map';
import PaymentCFRef from './PaymentCFRef'; // Import PaymentCFRef component

const PaymentCashfree = () => {
  const { addAlert } = useContext(AlertContext);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cashfree, setCashfree] = useState(null); // State for Cashfree SDK

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfreeInstance = await load({
          mode: "production", // Switch to "production" mode for production
        });
        setCashfree(cashfreeInstance);
      } catch (error) {
        console.error("Failed to initialize Cashfree SDK", error);
      }
    };

    initializeSDK();
  }, []);

  const getCurrencyFromCountry = (countryName) => {
    // Get the currency based on the country name
    const currency = getCountry(countryName);
    return currency.currency;
  };  
 
 
  const handlePayment = async () => {
    try {
      const database = getDatabase();
      const ordersRef = ref(database, 'orders');
      const userId = auth.currentUser.uid;
      
      // Fetch customer phone number and country name from profile node
      const kycDataRef = ref(database, `users/${userId}/profile/mobileNumber`);
      const countryNameRef = ref(database, `users/${userId}/profile/country`);
      const [kycDataSnapshot, countryNameSnapshot] = await Promise.all([
        get(kycDataRef),
        get(countryNameRef)
      ]);
      
      const customerPhone = kycDataSnapshot.val();
      const countryName = countryNameSnapshot.val();
      const currency = getCurrencyFromCountry(countryName);

      const snapshot = await get(ordersRef);
      let lastOrderId = 0;
      if (snapshot.exists()) {
        const orders = snapshot.val();
        const orderIds = Object.keys(orders);
        const orderValues = Object.values(orders);
        // Assuming each order object has an 'order_id' field
        const orderIdsWithValues = orderValues.map(order => order.order_id);
        lastOrderId = Math.max(...orderIdsWithValues);
      }
      
      // Generate a new unique order ID
      const newOrderId = lastOrderId + 1;
      // Create the new order data
      const response = await fetch(
        // 'http://127.0.0.1:5001/trilect-infra-stuck-pro-dev/us-central1/createorder',
        'https://us-central1-trilect-infra-stuck-pro-dev.cloudfunctions.net/createorder',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: userId,
            customer_phone: customerPhone,
            order_amount: 1070.60,
            order_currency: currency,
            order_id: newOrderId.toString(), // Convert to string for consistency
          }),
        }
      );

      if (response.ok) {
        const orderData = await response.json();
        console.log('Order Data:', orderData);
        
        // Push the new order data to the database
        const newOrderData = {
          order_id: newOrderId.toString(),
          customer_id: userId,
          customer_phone: customerPhone,
          order_amount: 1070.60,
          order_currency: currency,
          created_at: new Date().toISOString(), // Current timestamp
          payment_session_id: orderData.data.payment_session_id,
        };
        
        const newOrderRef = push(ordersRef);
        set(newOrderRef, newOrderData);
  
        // Display success message
        setSuccessMessage('Order created successfully!');
        
        // Initiate Cashfree checkout after order creation
        doPayment(orderData.data.payment_session_id);
      } else {
        console.error('Failed to create order:', response.statusText);
        setErrorMessage('Failed to create order. Please try again later.');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setErrorMessage('Error creating order. Please try again later.');
    }
  };

  const doPayment = async (orderId) => {
    if (!cashfree) {
      addAlert("Cashfree SDK is not initialized.");
      return;
    }
  
    try {
      const checkoutOptions = {
        paymentSessionId: orderId, // Use order ID as payment session ID
        redirectTarget: "_self", // Open checkout in the same tab
      };
  
      // Call cashfree.checkout() and handle the success scenario
      cashfree.checkout(checkoutOptions).then(() => {
        // If the promise resolves, it means the payment was successful
        addAlert("Payment Done Successfully");
      }).catch((error) => {
        // If there's an error during the payment process
        addAlert("Error during payment: " + error);
      });
    } catch (error) {
      // If there's an error before initiating the payment process
      addAlert("Error initiating payment: " + error);
    }
  };

  return (
    <div className="container mt-5">
      <div className='row justify-content-center'>
        <div className="col-md-6 border border-secondary rounded shadow py-3 px-5 text-center">
          <h3 className='text-center text-primary'>Payment By CashFree Payment Gateway</h3>
          <hr />
          <p className='text-start text-primary'>Dear customer, to make a payment to us (Trilect Infra Stuck Pro Dev Opc P Ltd) through Debit Card, Credit Card, UPI and other methods will cost Payment Gateway Charges approximately 2% extra. Click Pay Now Button.</p>
          <div className='row'>
            <div className='col-md-12'>
              {successMessage && <div className="alert alert-success mt-2">{successMessage}</div>}
              {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}
              <br />
              <button type="button" className="btn btn-success w-100" onClick={handlePayment}>
                Pay Now - Amount 1070.60 
              </button>
           
            </div>
          </div>
          <PaymentCFRef doPayment={doPayment} />
        </div>
        
      </div>
    </div>
  );
};

export default PaymentCashfree;
