import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database'; // Import the getDatabase function


const firebaseConfig = {
  apiKey: 'AIzaSyBDPZo5NT2V6Xiul3RqcQVoMfHUEjK7jYI',
  authDomain: 'trilect-infra-stuck-pro-dev.firebaseapp.com',
  projectId: 'trilect-infra-stuck-pro-dev',
  storageBucket: 'trilect-infra-stuck-pro-dev.appspot.com',
  messagingSenderId: '64160183645',
  appId: "1:64160183645:web:808397113964ff86fc24b4",
  measurementId: "G-63W6BVMEK6"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp); // Use getDatabase to initialize the database

export { auth, firebaseApp, database };