// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AlertProvider } from './components/AlertContext';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfile from './components/UserProfile';
import PaymentQR from './components/PaymentQR'
import PaymentCashfree from './components/PaymentCashfree'
import PaymentCFRef from './components/PaymentCFRef'
import Funds from './components/Funds'
import VideoBackground from './components/VideoBackground'; // Import VideoBackground
import { auth } from './firebaseconfig';
import Tmp from './components/Tmp';
import ApplyRefund from './components/ApplyRefund'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init({
  duration: 1000, // Animation duration (in milliseconds)
  once: true, // Whether animations should be triggered only once
  mirror: false, // Whether elements should animate when scrolling in the opposite direction
});

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    document.title = 'TISPD';
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();

  }, []);

  return (
    <Router>
      <VideoBackground /> {/* Render the VideoBackground component */}
      {currentUser && <Navbar />} {/* Render Navbar only if currentUser exists */}
      <div className="content">
      <AlertProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route 
            path="/UserProfile" 
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } 
          />
               <Route 
            path="/PaymentQR" 
            element={
              <ProtectedRoute>
                <PaymentQR />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/Funds" 
            element={
              <ProtectedRoute>
                <Funds />
              </ProtectedRoute>
            } 
          />
                    <Route 
            path="/PaymentCashfree" 
            element={
              <ProtectedRoute>
                <PaymentCashfree />
              </ProtectedRoute>
            } 
          />
           <Route 
            path="/PaymentCFRef" 
            element={
              <ProtectedRoute>
                <PaymentCFRef />
              </ProtectedRoute>
            } 
          />
           
                    <Route 
            path="/home" 
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } 
          />

                   
<Route 
            path="/Tmp" 
            element={
              <ProtectedRoute>
                <Tmp />
              </ProtectedRoute>
            } 
          />

<Route path="/ApplyRefund" element={<ProtectedRoute><ApplyRefund /> </ProtectedRoute>}/>


          
          <Route path="/register" element={<Register />} />
        </Routes>
        </AlertProvider>
      </div>
    </Router>
  );
}

export default App;
