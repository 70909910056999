// VideoBackground.js
import React from 'react';
import { useLocation } from 'react-router-dom';

// Inline CSS for the video background
const videoBackgroundStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  minWidth: '100%',
  minHeight: '100%',
  width: 'auto',
  height: 'auto',
  transform: 'translate(-50%, -50%)',
  zIndex: '-1', // Ensure the video stays in the background
};

const VideoBackground = () => {
  const location = useLocation();

  if (location.pathname === '/' || location.pathname === '/register' || location.pathname === '/login' ) {
    return (
      <video autoPlay muted loop style={videoBackgroundStyle}>
        <source src="/images/hero.mp4" type="video/mp4" />
      </video>
    );
  } else {
    return null;
  }
};

export default VideoBackground;
