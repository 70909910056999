import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseconfig';
import { getDatabase, ref, update, get } from 'firebase/database'; // Update import to include 'update' and 'get'
import axios from 'axios';

const ApplyRefund = ({ doPayment }) => {
  const [orders, setOrders] = useState([]);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    if (userId) {
      const database = getDatabase();
      const ordersRef = ref(database, 'orders');
      
      get(ordersRef).then((snapshot) => {
        if (snapshot.exists()) {
          const ordersData = snapshot.val();
          // Convert orders object to array
          const ordersArray = Object.entries(ordersData).map(([key, value]) => ({ ...value, key }));
          // Filter orders for the current user
          const filteredOrders = ordersArray.filter(order => order.customer_id === userId && order.payment_status === 'PAID')
          .sort((a, b) => b.order_id - a.order_id);
          setOrders(filteredOrders);
        } else {
          console.log('No orders found for the current user');
        }
      }).catch(error => console.error("Error getting orders:", error));
    }
  }, [userId]);

  const handleRetryPayment = (paymentSessionId) => {
    // Implement logic to retry payment using paymentSessionId
    doPayment(paymentSessionId);
  };

  const handleApplyRefund = async (orderId) => {
    try {

      // Find the key corresponding to the given orderId in the orders array
      const orderIndex = orders.findIndex(order => order.order_id === orderId);
      if (orderIndex !== -1) {
        // Update order status in the Firebase Realtime Database
        const orderKey = orders[orderIndex].key;
        const database = getDatabase();
        const orderRef = ref(database, `orders/${orderKey}`);
        await update(orderRef, { ApplyRefund: "Yes", ApplyRefundTime: new Date().toISOString() });
        
        // Update the local state to reflect the updated order status
        const updatedOrders = [...orders];
        updatedOrders[orderIndex] = { ...updatedOrders[orderIndex], ApplyRefund: "Yes", ApplyRefundTime: new Date().toISOString() };
        setOrders(updatedOrders);
        
        console.log('Order status updated successfully');
      } else {
        console.log('Order not found in local state');
      }
    } catch (error) {
      console.error('Error tracking order status:', error);
    }
  };

  return (
    <div className="container mt-5" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <div className='row justify-content-center'>
            <div className='col-md-8 bg-light border border-1 p-5 rounded'>
      <h3 className='text-center pb-5'>Refund Status</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Amount</th>
            <th>Payment Status</th>
            <th>Refund Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.key}> {/* Use 'key' instead of 'id' for the key attribute */}
              <td>{order.order_id}</td>
              <td>{order.order_currency} {order.order_amount}</td>
              <td>{order.payment_status}</td>
              <td>
              {order.ApplyRefund === "Yes" && (
                <span>Refund applied on {new Date(order.ApplyRefundTime).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</span>
                )}

                {order.ApplyRefund === "Issued" && (
                    <span>Refund is already Processed</span>
                )}
                {order.ApplyRefund !== "Yes" && order.ApplyRefund !== "Issued" && (
                    <button className='btn btn-info' onClick={() => handleApplyRefund(order.order_id)}>Apply For Refund</button>
                )}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
        </div>
  );
};

export default ApplyRefund;
