import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../firebaseconfig';
import { getDatabase, ref, set, get } from 'firebase/database';
import { AlertContext } from './AlertContext';

const PaymentQR = () => {
  const { addAlert } = useContext(AlertContext);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [referenceNumbers, setReferenceNumbers] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentData, setPaymentData] = useState({ refrenceNumbers: [] });

  useEffect(() => {
    const database = getDatabase();
    const userId = auth.currentUser.uid;
    const userPaymentRef = ref(database, `users/${userId}/payment`);

    get(userPaymentRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const paymentData = snapshot.val();
          setReferenceNumbers(paymentData.refrenceNumbers || []);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
        addAlert('Failed to fetch profile data.');
      });
  }, [addAlert]);

  const handleInputChange = (event) => {
    setReferenceNumber(event.target.value);
  };

  const addPayment = () => {
    const database = getDatabase();
    const userId = auth.currentUser.uid;
    const userPaymentRef = ref(database, `users/${userId}/payment`);

    const newReferenceNumbers = [...referenceNumbers, referenceNumber];

    set(userPaymentRef, { ...paymentData, refrenceNumbers: newReferenceNumbers })
      .then(() => {
        console.log('Payment data saved successfully.');
        setSuccessMessage('Payment added successfully!');
        addAlert('Payment updated successfully!, Payment Clearance will be subject to once our Team member approves it, it will start reflecting in your account. ');

        // Clear the input field after adding a payment
        setReferenceNumber('');

        setTimeout(() => {
          // Replace 'navigate' with the appropriate navigation method
          // Example: import { useHistory } from 'react-router-dom'; const history = useHistory();
          // history.push('/');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error saving Payment data:', error);
        addAlert(`Error saving payment data: ${error.message}`);
        setErrorMessage(`Error: ${error.message}`);
      });
  };

  return (
    <div className="container mt-5">
      <div className='row justify-content-center'>
        <div className="col-md-6 border border-secondary rounded shadow py-3 px-5 text-center">
          <h3 className='text-center text-primary'>Payment QR</h3>
          <hr />
          <p className='text-start text-primary'>Dear customer, to make a payment to us (Trilect Infra Stuck Pro Dev Opc P Ltd) scan this QR code using any UPI app (like PhonePe, Google Pay, Paytm, etc.). After scanning, please enter the reference number in the box and click submit.</p>
          <div className='row'>
            <div className='col-md-6'>
            <img src="/images/qr.jpg" alt="QR Code" />
            <h6 className='text-danger mt-3'>Fixed Amount Rs. 1000 </h6>   
            <label htmlFor="referenceInput">Enter Reference Number:</label>
            <br />
            <input
              type="text"
              id="referenceInput"
              value={referenceNumber}
              onChange={handleInputChange}
            />
            {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
            <br/>
            <button type="button" className="btn btn-primary mt-3" onClick={addPayment}>
              Save Payment Detail
            </button>
           
            </div>
            <div className='col-md-6'>
            <div className="mt-3">
            <h5>Previous Reference Numbers:</h5>
            <ul>
              {referenceNumbers.map((refNumber, index) => (
                <li key={index}>{refNumber}</li>
              ))}
            </ul>
          </div>
            </div>
          </div>
          <div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PaymentQR;
