import React from 'react';
import { Chart } from 'react-google-charts';

const Funds = () => {
  const data = [
    // Add column headers as the first row
    ['Month', 'Fund Value', 'Refund Value'],
  ];

  let refundValue = 1000; // Initialize with starting fund value (principal)

  // Generate data for 36 months with linear progress and specific formula
  for (let i = 1; i <= 36; i++) {
    const fundValue = Math.round(1000 + (i / 36) * (2000 - 1000));

    // Calculate refund based on your formula, starting from 4th month
    const calculatedRefund = i > 3 ? 1000 + (1000 * (i - 1) * 1 / 100) : 0;

    // Adjust refund value based on specific requirements and cap at 2000
    refundValue = Math.min(
      Math.max(calculatedRefund, refundValue), // Ensure at least the previous refund
      2000
    );

    // Set specific value for final month (36th)
    if (i === 36) {
      refundValue = 2000;
    }

    data.push([i, fundValue, refundValue]);
  }

  // Create refund table data (optional)
  const refundTableData = data.slice(1); // Exclude header row

  // Render the refund table (requires additional libraries or framework)
  // Example with Bootstrap:
  const renderRefundTable = () => {
    return (
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Month</th>
            <th>Fund Value (₹)</th>
            <th>Refund Value (₹)</th>
          </tr>
        </thead>
        <tbody>
          {refundTableData.map((row) => (
            <tr key={row[0]}>
              <td>{row[0]}</td>
              <td>{row[1].toFixed(2)}</td>
              <td>{row[2].toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="container mt-5" >
      <div className="row justify-content-center">
        <div className="col-md-9 border border-secondary rounded shadow py-3 px-5 text-center" style={{ overflow: 'scroll', height: '600px' }}>
          <h3 className="text-center text-primary">Fund Progress with Refund</h3>
          <Chart
            chartType="LineChart"
            data={data}
            options={{
              title: '36-Month Fund Progress (Start: ₹1000, End: ₹2000)',
              hAxis: { title: 'Month' },
              vAxis: { title: 'Value (₹)' },
              series: {
                // Style fund value line
                0: { color: 'blue' },
                // Style refund value line in red
                1: { color: 'red' },
              },
              trendline: {
                visible: true,
                type: 'linear',
                color: 'green',
              },
            }}
          />
          <p>Note: First 3 month will be lock period, Refund will be allowed from 4th month.</p>
          {/* Replace "renderRefundTable()" with your preferred table implementation */}
          {renderRefundTable()}
        </div>
      </div>
    </div>
  );
};

export default Funds;
